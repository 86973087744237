import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DataTable } from 'src/app/data';
import { NavigationEnd, Router } from '@angular/router';
import { Nav, NavItem, Param, Procedure, WidgetEvent } from 'src/app/widgets';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { NetworkService } from 'src/app/services/network.service';
import { CmdComponent } from '../cmd/cmd.component';

@Component({
  selector: 'app-main-search',
  templateUrl: './main-search.component.html',
  styleUrls: ['./main-search.component.scss']
})
export class MainSearchComponent extends CmdComponent implements OnInit, OnDestroy {
  public widgetId = 'main-search';
  public component = this;

  public searchInfo = false;
  public searchTxt = '';
  public searchDropDown = false;
  public resultIndex = -1;
  public _searchToggle = false;

  public currentNavItem: NavItem;

  @Input() searchUrl = 'xfw/nl/zoek/';

  @Input() hideSearch = false;
  @Input() hideLanguage = false;

  @Output() searchToggle: EventEmitter<any> = new EventEmitter();

  public searchContainer = 'search';
  public searchKey = 'searchTxt';
  public user: any;

  @ViewChildren('result') result: QueryList<ElementRef> | undefined;

  public dataTable: DataTable[] = [{
    src: '',
    queryParams: [],
    data: []
  }];

  public searchNav: Nav;
  public searchTypeNav: Nav;

  @ViewChild('searchInput') searchInput: ElementRef;

  onSearchToggle(event) {
    this.searchToggle.emit(event);
  }

  initNav() {
    this.searchNav = {
      navId: -100,
      menu: [
        {
          navItemId: 1,
          className: 'sm xfw3-bg-secondary round icon',
          icon: 'fa-regular fa-magnifying-glass fa-fw',
          noToggle: true,
          text: '',
          info: 'Zoeken',
          func: 'search',
        }
      ]
    };
  }

  ngOnInit(): void {
    this.nav = this.dataService.searchNav;
    this.initNav();

    setTimeout(() => {
      if(!this.dataService || !this.dataService.searchNav) {
        window.location.reload();
      }
    }, 2000);
  }

  ngOnDestroy() {

  }

  searchInfoToggle(value) {
    this.searchInfo = value;
    !value && (this.searchDropDown = false);
  }

  searchList(event: KeyboardEvent) {
    if (!['ArrowUp', 'ArrowDown', 'Enter', 'Tab'].includes(event.key)
      && this.searchTxt.length >= 1) {

      let currentNavItemCode: string = null;

      !this.dataService.searchNav.menu.some(x => x.info === this.searchTxt.charAt(0)) ?
        currentNavItemCode = '~'
        :
        currentNavItemCode = this.searchTxt.charAt(0);

      this.currentNavItem = this.dataService.searchNav.menu.find(x => x.info === currentNavItemCode);

      !this.currentNavItem?.src ? (this.searchDropDown = this.searchInfo = false) : (this.searchInfo = true);


      if (currentNavItemCode !== '~' && this.currentNavItem?.src && this.searchTxt.length >= 2) {
        this.searchTxt.length >= 2 && this.currentNavItem?.src && this.networkService.query([{
          src: this.currentNavItem.src,
          params: [{
            key: 'searchTxt',
            val: this.searchTxt.substring(this.currentNavItem.startSearchTxt, this.searchTxt.length)
          }]
        }], 'json').subscribe(dataTable => {
          this.dataTable = dataTable;

          this.currentNavItem.startSearchTxt !== 0 && this.dataTable[0].data.some(row => row.text.toLowerCase().includes(this.searchTxt.toLowerCase().substring(this.currentNavItem.startSearchTxt, this.searchTxt.length))) ?
            this.resultIndex = this.dataTable[0].data.findIndex(row => row.text.toLowerCase().includes(this.searchTxt.toLowerCase().substring(this.currentNavItem.startSearchTxt, this.searchTxt.length)))
            :
            this.resultIndex = -1;

          this.searchDropDown = true;
        });
      }
    }
  }

  scrollIntoView() {
    setTimeout(() => {
      this.result.toArray()[this.resultIndex].nativeElement.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'center'
      });
    });
  }

  checkSpecialKeys(event: KeyboardEvent) {
    switch (event.key) {
      case 'Esc':
        this.searchDropDown = false;
        this.searchInfo = false;
        this.searchInput.nativeElement.blur();
        break;
      case 'ArrowUp':
        (['.', '-'].includes(this.searchTxt.charAt(0))) &&
          (this.resultIndex >= 0) &&
          (this.searchTxt = '.' + this.dataTable[0].data[--this.resultIndex].text) &&
          this.scrollIntoView();
        break;
      case 'ArrowDown':
        (['.', '-'].includes(this.searchTxt.charAt(0))) &&
          (this.resultIndex < this.dataTable[0].data.length - 1) &&
          (this.searchTxt = '.' + this.dataTable[0].data[++this.resultIndex].text) &&
          this.scrollIntoView();
        break;
      case 'Backspace':
        (this.searchTxt.length === 1) && (this.searchInfo = !(this.searchDropDown = false));
        (this.searchTxt.length === 0) && (this.searchInfo = false);
        (this.searchTxt.length <= 1) && (this.dataTable[0].data = []);
        break;
      case 'Tab':
      case 'Enter':
        if ((['.', '-'].includes(this.searchTxt.charAt(0))) && this.searchTxt.length > 1) {
          this.resultIndex === -1 && this.dataTable[0].data.length && (this.resultIndex = 0);
          this.searchTxt = this.searchTxt.charAt(0) + this.dataTable[0].data[this.resultIndex].text;
        }

        this.search();
        break;
    }
  }

  search(resultIndex: number = -1) {
    resultIndex >= 0 && (this.resultIndex = resultIndex);

    (this.searchTxt.trim() === '') && (this.searchTxt = '');

    if (!this.searchTxt) {
      this._searchToggle = !this._searchToggle;
      this.onSearchToggle({ searchToggle: this._searchToggle });
    } else {

      const searchTxt = this.searchTxt.substring(this.currentNavItem.startSearchTxt, this.searchTxt.length).trim();
      let item: any = {};

      if (this.searchTxt.length > 1) {
        !['=', '@'].includes(this.currentNavItem.info) && this.resultIndex >= 0 && (item = this.dataTable[0].data[this.resultIndex]);
        switch (this.currentNavItem.info) {
          case '=':
            this.searchTxt = '=' + eval(searchTxt);
            break;
          case '@':
            this.searchDropDown = false;

            this.currentNavItem.params.forEach(paramFrom => {
              item[paramFrom.key] = searchTxt
            });
          default:
            !Object.entries(item).length && (item = { searchTxt: searchTxt });

            this.currentNavItem.params.forEach(param => {
              param.val = item[param.key];
            });

            if (Object.entries(item).length) {
              this._perform(this.currentNavItem);
              this.searchInfo = this.searchDropDown = false;
              this.currentNavItem.info !== '@' && (this.searchTxt = '');
            } else {
              this.searchDropDown = false;
            }
        }
      }
    }
  }

  trackByFn(index, item) {
    return index;
  }
}
