import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { DomainConfigService } from 'src/app/services/domain-config.service';
import { UserService } from 'src/app/services/user.service';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() contentJSON: any;
  @Input() carousel: any[] = [];
  @Input() environmentJSON: any;

  @Input() meta: any;
  // = {
  //   hideSearch: false, // speciaal voor de main-search. Nog aanpassen
  //   searchUrl: 'xfw/nl/zoek/', // speciaal voor de main-search. Nog aanpassen
  //   carouselData: {}
  // }

  // This fix is temporary. Beta merge should defenetly overwrite this.
  public isInhaker = this.userService._user.domainId === 3;

  public searchToggle: boolean = false;

  constructor(
    public dataService: DataService,
    public domainConfigService: DomainConfigService,
    private router: Router,
    private userService: UserService) {

    }

  ngOnInit(): void {

  }

  navigate(route: string[]) {
    this.router.navigate(route);
  }

  onSearchToggle(event) {
    this.searchToggle = event.searchToggle;
  }
}
